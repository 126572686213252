:root {
    --description-font-size: 1.5rem; /* Default font size for game description */
    --cta-font-size: 1.3rem; /* Default font size for CTA text */
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; 
}

/* General styles */
.landing-wrapper {
    transform: scale(0.90); /* Adjust the scaling as needed */
    
}

.highlighted-text {
    color: #F68B1F; /* Basketball orange color */
    font-weight: bold;
}

.hero-text {
    margin-top: 20vh;
    display: inline-block; 
    position: relative;
}

.hero-text h1 {
    font-family: 'Luckiest Guy', cursive;
    font-size: 4rem;
    color: #FFA500; 
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
}

p.game-description {
    font-family: 'Graduate', sans-serif; 
    font-size: 1.5rem;
    color: white;
    margin-top: 20px;
    line-height: 1.5;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.cta-text {
    margin-top: 70px;
    font-size: 1.3rem;
    color: #ffffff;
    font-family: 'Graduate', sans-serif;
}

.floating-grid {
    display: inline-block;
    width: 100px; 
    margin-right: 20px;
    margin-top: -30px;
    vertical-align: middle;
    animation: floatAround 5s ease-in-out infinite;
}

@keyframes floatAround {
    0% { transform: translate(0, 0); }
    25% { transform: translate(-10px, -10px); }
    50% { transform: translate(10px, -15px); }
    75% { transform: translate(-5px, 10px); }
    100% { transform: translate(0, 0); }
}

.bouncing-basketball-icon {
    display: inline-block;
    width: 50px;
    margin-left: 20px;
    vertical-align: middle;
    animation: bounce 1.5s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-15px); }
    60% { transform: translateY(-7px); }
}

.ball-knowledge-text {
    margin-top: 60px; 
}

.ball-knowledge-text h2 {
    font-family: 'Luckiest Guy', cursive;
    font-size: 5rem; 
    color: #fad60a; 
    text-transform: uppercase;
    margin-bottom: 20px;
}

/* Mobile-specific styles */
@media (max-width: 691px) {
  .hero-text,
  .ball-knowledge-text {
    display: none; /* Hide the welcome text and challenge statement on mobile */
  }

  .game-mode-buttons {
    transform: scale(1.90);
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 200px;
  }
}

/* Desktop-specific styles */
@media (min-width: 692px) {
  .game-mode-buttons {
    display: none; /* Hide game mode buttons on desktop */
  }
}
