.App .footer {
  background-color: #292929; /* Dark gray almost black */
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 10px 0; /* Reduce the padding to make the footer shorter */
  position: fixed; /* Fix the footer to the bottom of the viewport */
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.9rem; /* Reduce the overall font size */
}

.App .footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App .footer-content p {
  margin-bottom: 5px; /* Adjust margin to create space between text and button */
  font-size: 0.8rem; /* Make the disclaimer text smaller */
}

.App .connectBtn {
  width: 100px; /* Adjust the button width to be smaller */
  height: 30px; /* Adjust the button height to be smaller */
  border: none;
  border-radius: 20px; /* Keep the roundness but scale it down */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px; /* Slightly reduce the gap between icon and text */
  color: white;
  font-weight: 600;
  font-size: 0.8rem; /* Reduce font size of the button text */
  background: linear-gradient(to right, #8be3fc, #576bff);
  box-shadow: 0 15px 20px -5px rgba(97, 118, 238, 0.5);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.App .connectBtn:hover {
  box-shadow: none;
  transform: translate(0px, 2.2px);
}

.App .connectBtn:active {
  transform: scale(0.96) translate(0px, 3.2px);
}

/* Media Query for Mobile Devices */
@media (max-width: 691px) {
  .App .footer {
    font-size: 0.2em; /* Smaller font size for mobile */
    padding: 5px 0; /* Less padding on mobile */
  }

  .App .footer-content p {
    font-size: 0.4rem; /* Smaller text for mobile */
  }

  .App .connectBtn {
    width: 80px; /* Smaller button width on mobile */
    height: 25px; /* Smaller button height on mobile */
    font-size: 0.7rem; /* Smaller button text size on mobile */
  }
}
