@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-content {
  background-color: #fde9bd; /* Change this to your desired background color */
  padding: 20px;
  flex: 1; /* This ensures the main content area takes up remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Ensure children are in a column layout */
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-wrapper {
  transform: scale(0.75); /* Scale down the entire game area */
 /* Anchor the scaling to the top-left corner */
  margin: 0 auto; /* Center the content horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.board-container {
  display: grid;
  grid-template-areas:
    "category-top category-top category-top category-top"
    "category-side square square square"
    "category-side square square square"
    "category-side square square square";
  gap: 10px;
  justify-content: center;
  width: 80vw; /* Adjust the width as needed */
  max-width: 600px; /* Set a max size */
  margin-top: -20px; /* Adjust this value to shift the grid up or down */
  transform: translateX(-100px);

}

.categories-top {
  display: grid;
  grid-area: category-top;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  width: 100%; /* Ensure it takes the full width of the board container */
  max-width: 600px; /* Match the max width of the board */
  margin: 0 auto; /* Center the categories-top container */
  transform: translateX(100px);
}

.categories-side {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 7px;
  width: 200px; /* Adjust width as needed to properly display the images */
  grid-area: category-side;
  justify-content: center;
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  height: 100%; /* Ensures the category item takes up the full height of the grid area */
  background: none; /* Remove background */
  border: none; /* Remove border */
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  justify-content: center;
  grid-area: square;
  width: 80vw;  /* Adjust the width as needed */
  height: 80vw; /* Keep it square by using the same value for height */
  max-width: 600px;  /* Set a max size */
  max-height: 600px; /* Set a max size */
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* Full white background for the squares */
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  flex-direction: column; /* Ensure the image and text stack vertically */
  overflow: hidden; /* Ensure content doesn't overflow */
  position: relative; /* Position for absolute image */
}

.square img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1; /* Place the image behind the text */
}

.square div {
  position: relative;
  z-index: 2; /* Place the text on top of the image */
  background-color: rgba(0, 0, 0, 0.8); /* Black background with minimal opacity */
  color: white; /* White text */
  padding: 5px 0; /* Adjust padding to match the new width */
  width: 100%; /* Make the background stretch across the square */
  text-align: center; /* Center the text */
  margin-top: auto; /* Shift the text down */
  font-size: 16px; /* Make the font size smaller */
  line-height: 1.2; /* Reduce space between lines */
  font-weight: bold; /* Make the text bold */
}

.square:hover {
  background-color: #d0d0d0;  /* Highlight color on hover */
}

.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  align-self: flex-end;
  cursor: pointer;
  font-size: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.search-input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.player-list {
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.player-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.player-item:hover {
  background-color: #f0f0f0;
}

.footer {
  background-color: #292929; /* Dark gray almost black */
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 20px 0;
  position: fixed; /* Fix the footer to the bottom of the viewport */
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connectBtn {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: white;
  font-weight: 600;
  background: linear-gradient(to right, #8be3fc, #576bff);
  box-shadow: 0 20px 30px -7px rgba(97, 118, 238, 0.5);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.connectBtn:hover {
  box-shadow: none;
  transform: translate(0px, 2.2px);
}

.connectBtn:active {
  transform: scale(0.96) translate(0px, 3.2px);
}

/* Add these new styles to your existing App.css */

.game-info {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.reset-button {
  padding: 10px 20px;
  font-size: 16px;
  background: linear-gradient(to right, #8be3fc, #576bff);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 20px 30px -7px rgba(97, 118, 238, 0.5);
}

.reset-button:hover {
  box-shadow: none;
  transform: translate(0px, 2.2px);
}

.reset-button:active {
  transform: scale(0.96) translate(0px, 3.2px);
}

.square {
  border: 4px solid transparent;
  transition: all 0.3s ease;
}

.square.red {
  border-color: #ff4136;
}

.square.blue {
  border-color: #0074d9;
}

.square.hoverable:hover {
  background-color: #f0f0f0;
}

.square:not(.hoverable) {
  cursor: default;
}

.player-headshot {
  width: 80%;
  height: 80%;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 10px;
}


.game-info {
  text-align: center;
  transform: translateY(-40px);
  margin-top: -60px;
}

.game-info h2 {
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.game-info.red h2 {
  border: 2px solid red;
  color: red;
}

.game-info.blue h2 {
  border: 2px solid blue;
  color: blue;
}

.game-info.black h2 {
  border: 2px solid black;
  color: black;
}

.button {
  --main-focus: #2d8cf0;
  --font-color: #dedede;
  --bg-color-sub: #222;
  --bg-color: #323232;
  --main-color: #dedede;
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px; /* Add this line to shift the button down */
}

.button, .button__icon, .button__text {
  transition: all 0.3s;
}

.button .button__text {
  transform: translateX(33px);
  color: var(--font-color);
  font-weight: 600;
}

.button .button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: var(--bg-color-sub);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .svg {
  width: 20px;
  fill: var(--main-color);
}

.button:hover {
  background: var(--bg-color);
}

.button:hover .button__text {
  color: transparent;
}

.button:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.button:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}

.about-btn .front.text {
  font-weight: bold;  /* Make the text bold */
  color: white;       /* Set the text color to white */
}

.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  text-align: center;
  transform: translateY(-10%);
}

.coming-soon-title {
  font-size: 3rem; /* Large title */
  font-weight: bold;
  margin-bottom: 20px;
}

.coming-soon-text {
  font-size: 2rem; /* Large text */
  font-weight: bold;
  color: #ff5733; /* Add a color if you like */
}

.invalid-player {
  color: red;
  font-weight: bold;
}


@media (max-width: 691px) {
  .game-wrapper {
    transform: scale(0.7); /* Smaller scale for mobile devices */
    
  }
  .categories-top {
    transform: scale(0.6) translateX(120px); /* Scale down and adjust positioning */
    max-width: 480px; /* Adjust max width to fit smaller screens */
  }

  .categories-side {
    transform: scale(0.6) translateY(-200px);
    width: 150px; /* Adjust width for smaller screens */
    gap: 50px;
  }

  .category-item {
    font-size: 0.8rem; /* Reduce font size for mobile */
    padding: 3px; /* Adjust padding for mobile */
  }
  .button{
    transform: translateY(-200px);
  }
  .board-container{
    margin-left: 80px;
  }
  
}

@media (min-width: 400px) and (max-width: 690px) {
  .button {
    transform: translateY(100px); /* Lower the button for smaller screens */
  }
}

